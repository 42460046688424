.l-section-quote {
  background-color: $blue;
  padding: ru(3) ru(1);

  @include breakpoint($bp-large) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}

.l-section-quote .m-blockquote {
  text-align: center;
  margin: 0;

  @include breakpoint($bp-large) {
    grid-column: 4 / 10;
  }
}

.l-section-quote .m-blockquote__body {
  margin-bottom: ru(1);
}

.l-section-quote .m-blockquote__footer {
  font-style: normal;
}

