.m-blockquote {
  color: $white;
}

.m-blockquote__body {
  position: relative;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 0 0.5rem;

  &:before {
    display: block;
    content: '\201C';
    position: absolute;
    top: -0.75rem;
    left: -1.5rem;
    color: $white;
    font-size: 4rem;
    line-height: 4rem;
 }


 &:after {
  display: block;
  content: '\201D';
  position: absolute;
  right: -1.5rem;
  bottom: -2.5rem;
  color: $white;
  font-size: 4rem;
 }

}

.m-blockquote__footer {

}

