%m-message,
.m-message {
  background-color: palette(grey, light);
  padding: ru(1);
  width: 100%;
  color: $white;


}

.m-message__wrapper {
  // @extend %l-container-h__wrapper;
  padding: 0 $gutter;

}

.m-message__title {
  color: $white;
}

.m-message__text {
 
}


//Browser update message
.m-message--browser {
  @extend %m-message;

  background-color: palette(blue, dark);

  a {


    &:visited,
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}
