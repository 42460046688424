// sass-lint:disable-all

// Hacked version of Normalize used as a base reset. Last Duplicated w/ v5.0.3
// https://github.com/JohnAlbin/normalize-scss

/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */

$legacy_browser_support: false !default;

/* Base
   ========================================================================== */

// REMOVED

/* HTML5 display definitions
   ========================================================================== */

/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */

audio,
canvas,
progress,
video {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */

  // @if $legacy_browser_support {
  //   *display: inline;
  //   *zoom: 1;
  // }
}

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  vertical-align: baseline;
}

/**
 * Add the correct display in IE <11, Safari <8, and Firefox <22.
 * 1. Add the correct display in IE.
 */

template, /* 1 */
[hidden] {
  display: none;
}

// /* Links
//    ========================================================================== */

// /**
//  * 1. Remove the gray background on active links in IE 10.
//  * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
//  */

// a {
//   background-color: transparent; /* 1 */
//   -webkit-text-decoration-skip: objects; /* 2 */
// }

// /**
//  * Remove the outline on focused links when they are also active or hovered
//  * in all browsers (opinionated).
//  */

// a:active,
// a:hover {
//   outline-width: 0;
// }


/* Text-level semantics
   ========================================================================== */

/**
 * 1. Remove the bottom border in Firefox <40.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */

b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

%monospace,
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  @if support-for(ie, 6) {
    _font-family: 'courier new', monospace;
  }
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font style in Android <4.4.
 */

dfn {
  font-style: italic;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */

/** HEADINGS REMOVED AS WE ALWAYS STYLE THEM **/

/**
 * Addresses styling not present in IE 8/9.
 */

mark {
  background: #ffff00;
  color: #000000;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */

/**
 * Remove the border on images inside links in IE <11.
 */

img {
  border-style: none;
  max-width: 100%;
  // @if support-for(ie, 7) {
  //   /* Improve image quality when scaled in IE 7. */
  //   -ms-interpolation-mode: bicubic;
  // }
}

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}



/* Grouping content
   ========================================================================== */

/**
 * Address margin not present in IE 8/9 and Safari.
 */

figure {
  margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Contain overflow in all browsers.
 */

pre {
  @extend %monospace;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
  background-color: #eee;
  overflow: auto;
}

/* Forms
   ========================================================================== */

/**
 * Known issues:
 * - By default, Chrome on OS X and Safari on OS X allow very limited styling of
 *   select, unless a border property is set. The default font weight on
 *   optgroup elements cannot safely be changed in Chrome on OSX and Safari on
 *   OS X.
 * - It is recommended that you do not style checkbox and radio inputs as
 *   Firefox's implementation does not respect box-sizing, padding, or width.
 * - Certain font size values applied to number inputs cause the cursor style of
 *   the decrement button to change from default to text.
 * - The search input is not fully stylable by default. In Chrome and Safari on
 *   OSX/iOS you can't control font, padding, border, or background. In Chrome
 *   and Safari on Windows you can't control border properly. It will apply
 *   border-width but will only show a border color (which cannot be controlled)
 *   for the outer 1px of that border. Applying -webkit-appearance: textfield
 *   addresses these issues without removing the benefits of search inputs (e.g.
 *   showing past searches). Safari (but not Chrome) will clip the cancel button
 *   on when it has padding (and textfield appearance).
 */

form {
  margin: 0;
}


/**
 * 1. Change font properties to `inherit` in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 * 3. Address `font-family` inconsistency between `textarea` and other form in IE 7
 * 4. Improve appearance and consistency with IE 6/7.
 */

button,
input,
optgroup,
select,
textarea {
  font: inherit; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 */

button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select { /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */

button,
html [type="button"], /* 1 */
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {

  /**
   * Remove the inner border and padding in Firefox.
   */

  &::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  /**
   * Restore the focus styles unset by the previous rule.
   */

  &:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
}

/**
 * Show the overflow in Edge.
 */

input {
  overflow: visible;
  // Firefox sets `line-height` using `!important` in the UA stylesheet.
  line-height: normal;

}

/**
 * 1. Add the correct box sizing in IE <11.
 * 2. Remove the padding in IE <11.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */

  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
   */

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 * 4. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  box-sizing: border-box; /* 1 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
  color: inherit; /* 2 */
  padding: 0; /* 3 */
}

/**
 * Restore the font weight unset by a previous rule.
 */

optgroup {
  font-weight: bold;
}

/**
 * Remove the default vertical scrollbar in IE.
 */

textarea {
  overflow: auto;
}

/* Tables
   ========================================================================== */

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

// sass-lint:enable-all

