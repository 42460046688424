/* autoprefixer grid: on */
/**
 * Breakpoint Configuration
 * @see https://github.com/Team-Sass/breakpoint/wiki
 *
 */
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Add the correct display in IE <11, Safari <8, and Firefox <22.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none;
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox <40.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  _font-family: 'courier new', monospace;
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font style in Android <4.4.
 */
dfn {
  font-style: italic;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
/** HEADINGS REMOVED AS WE ALWAYS STYLE THEM **/
/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ffff00;
  color: #000000;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE <11.
 */
img {
  border-style: none;
  max-width: 100%;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Contain overflow in all browsers.
 */
/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
  background-color: #eee;
  overflow: auto;
}

/* Forms
   ========================================================================== */
/**
 * Known issues:
 * - By default, Chrome on OS X and Safari on OS X allow very limited styling of
 *   select, unless a border property is set. The default font weight on
 *   optgroup elements cannot safely be changed in Chrome on OSX and Safari on
 *   OS X.
 * - It is recommended that you do not style checkbox and radio inputs as
 *   Firefox's implementation does not respect box-sizing, padding, or width.
 * - Certain font size values applied to number inputs cause the cursor style of
 *   the decrement button to change from default to text.
 * - The search input is not fully stylable by default. In Chrome and Safari on
 *   OSX/iOS you can't control font, padding, border, or background. In Chrome
 *   and Safari on Windows you can't control border properly. It will apply
 *   border-width but will only show a border color (which cannot be controlled)
 *   for the outer 1px of that border. Applying -webkit-appearance: textfield
 *   addresses these issues without removing the benefits of search inputs (e.g.
 *   showing past searches). Safari (but not Chrome) will clip the cancel button
 *   on when it has padding (and textfield appearance).
 */
form {
  margin: 0;
}

/**
 * 1. Change font properties to `inherit` in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 * 3. Address `font-family` inconsistency between `textarea` and other form in IE 7
 * 4. Improve appearance and consistency with IE 6/7.
 */
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
  line-height: normal;
}

/**
 * 1. Add the correct box sizing in IE <11.
 * 2. Remove the padding in IE <11.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
   */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 * 4. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
  color: inherit;
  /* 2 */
  padding: 0;
  /* 3 */
}

/**
 * Restore the font weight unset by a previous rule.
 */
optgroup {
  font-weight: bold;
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
ul,
ol {
  margin: 0;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-position: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-size: inherit;
}

@viewport {
  width: device-width;
}

html {
  line-height: 1.5em;
  color: #4c4c4c;
  font-family: "proxima-nova", "Arial", sans-serif;
  font-size: 100%;
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-feature-settings: 'liga', 'dlig';
  font-variant-ligatures: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  height: 100%;
  min-height: 100vh;
}

p {
  font-size: 1.125rem;
  line-height: 1.33333;
  margin: 0 0 1rem 0;
}


.a-type--small {
  font-size: 1rem;
  line-height: 1.5;
}


.a-type--large {
  font-size: 1.375rem;
  line-height: 1.09091;
}

@media (min-width: 37.5em) {
  
  .a-type--large {
    font-size: 1.625rem;
    line-height: 1.15385;
  }
}


.a-type--xlarge {
  font-size: 1.5rem;
  line-height: 1.25;
}

@media (min-width: 37.5em) {
  
  .a-type--xlarge {
    font-size: 1.875rem;
    line-height: 1.2;
  }
}

img,
picture {
  display: block;
  width: 100%;
  height: auto;
}

.m-blockquote {
  color: white;
}

.m-blockquote__body {
  position: relative;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 0 0.5rem;
}

.m-blockquote__body:before {
  display: block;
  content: '\201C';
  position: absolute;
  top: -0.75rem;
  left: -1.5rem;
  color: white;
  font-size: 4rem;
  line-height: 4rem;
}

.m-blockquote__body:after {
  display: block;
  content: '\201D';
  position: absolute;
  right: -1.5rem;
  bottom: -2.5rem;
  color: white;
  font-size: 4rem;
}

.linkedin-profile {
  flex: 1;
  margin-left: 0;
  display: flex;
}

.linkedin-profile:hover .linkedin-profile__name,
.linkedin-profile:hover .linkedin-profile__position {
  color: #31bef8;
}

@media (min-width: 56.25em) {
  .linkedin-profile {
    margin-bottom: 0;
  }
}

.linkedin-profile__content {
  min-width: 9rem;
}

.linkedin-profile__image {
  min-width: 75px;
  margin-right: 1.5rem;
}

.linkedin-profile__img {
  display: inline-block;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
}

.linkedin-profile__svg {
  width: 24px;
  height: 24px;
  fill: black;
  vertical-align: middle;
  display: inline-block;
}

.linkedin-profile__name {
  text-transform: uppercase;
  color: #285b8d;
}

.linkedin-profile__position {
  margin-bottom: 0.375rem;
  color: black;
}

.linkedin-profile--light .linkedin-profile__name,
.linkedin-profile--light .linkedin-profile__position {
  color: white;
}

.linkedin-profile--light .linkedin-profile__svg {
  fill: white;
}

.m-logo__link {
  color: #31bef8;
  text-transform: uppercase;
  font-size: 2rem;
  font-family: "brandon-grotesque", "Arial", sans-serif;
  font-weight: 700;
}

@media (min-width: 56.25em) {
  .m-logo__link {
    font-size: 3rem;
  }
}

.m-message--browser,
.m-message {
  background-color: #999999;
  padding: 1.5rem;
  width: 100%;
  color: white;
}

.m-message__wrapper {
  padding: 0 ru(0.5);
}

.m-message__title {
  color: white;
}

.m-message--browser {
  background-color: #1d4268;
}

.m-message--browser a:visited, .m-message--browser a:hover, .m-message--browser a:focus, .m-message--browser a:active {
  text-decoration: underline;
}

.l-footer {
  padding: 3rem 1.5rem;
  text-align: center;
}

.l-footer .m-logo__link {
  font-size: 2rem;
  color: #285b8d;
}

.l-footer .m-logo__link span:last-child {
  color: #31bef8;
}

.l-footer .m-logo__link:hover, .l-footer .m-logo__link:focus, .l-footer .m-logo__link:active {
  text-decoration: underline;
}

.l-header__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(5.25rem, max-content);
  overflow: hidden;
}

@media (min-width: 56.25em) {
  .l-header__wrapper {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: minmax(min-content, max-content) minmax(5.25rem, max-content);
  }
}

.l-header {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
  background-color: #285b8d;
  background-image: url("/assets/img/bg-1.jpg");
  background-size: cover;
}

@media (min-width: 56.25em) {
  .l-header {
    grid-column: 1 / span 12;
    grid-row: 1 / span 1;
  }
}

.header__content {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  margin-bottom: 1.5rem;
  padding-top: 3rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

@media (min-width: 56.25em) {
  .header__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 2 / span 5;
    grid-row: 1 / span 2;
    padding-top: 3rem;
    padding-right: 0;
    padding-bottom: 9rem;
  }
}

.header__image {
  grid-column: 1 / span 1;
  grid-row: 2 / span 2;
  padding-top: 2.25rem;
  padding-right: 4.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0.75rem;
}

@media (min-width: 56.25em) {
  .header__image {
    display: flex;
    align-items: flex-end;
    grid-column: 7 / span 6;
    grid-row: 1 / span 2;
    margin-right: -1.5rem;
    padding-top: 2.25rem;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    opacity: 0;
    transform: translate3d(100px, 0, 0);
    animation: reveal-up 1s 0s cubic-bezier(0.215, 0.61, 0.355, 1) 1 forwards;
  }
}

.header__logo {
  margin-bottom: 3rem;
  opacity: 0;
  transform: translateY(100px);
  animation: reveal-up 1s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 1 forwards;
}

@media (min-width: 56.25em) {
  .header__logo {
    margin-bottom: 4.5rem;
  }
}

.l-header__wrapper .m-logo__link {
  color: white;
}

.l-header__wrapper .m-logo__link span:last-child {
  color: #31bef8;
}

.header__title {
  margin-bottom: 2.25rem;
  line-height: 1;
  color: white;
  font-size: 4rem;
  opacity: 0;
  transform: translateY(100px);
  animation: reveal-up 1s 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 1 forwards;
}

.header__sub-title {
  opacity: 0;
  transform: translateY(100px);
  animation: reveal-up 1s 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 1 forwards;
  color: white;
}

.header__sub-title-logo {
  display: inline-block;
  margin-bottom: 0.75rem;
  margin-right: 0.75rem;
}

.header__sub-title-logo:hover, .header__sub-title-logo:active {
  outline: 1px solid #31bef8;
}

.header__sub-title-logo-svg {
  vertical-align: middle;
  width: 163px;
  height: 33px;
}

.header__sub-title-logo__hidden-text {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.header__sub-title-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.3rem;
}

@keyframes reveal-up {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.l-page {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(min-content, max-content) minmax(5.25rem, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(5.25rem, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
}

.l-page__header {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
  z-index: 1;
}

.l-page__solutions {
  grid-column: 1 / span 1;
  grid-row: 2 / span 2;
  outline: 2px dashed #a00000;
  background-color: rgba(160, 0, 0, 0.5);
}

.l-page__brand {
  grid-column: 1 / span 1;
  grid-row: 4 / span 1;
}

.l-page__copy {
  grid-column: 1 / span 1;
  grid-row: 5 / span 1;
}

.l-page__photo {
  grid-column: 1 / span 1;
  grid-row: 6 / span 2;
}

.l-page__web {
  grid-column: 1 / span 1;
  grid-row: 7 / span 2;
}

.l-page__quote {
  grid-column: 1 / span 1;
  grid-row: 9 / span 1;
}

.l-page__contact {
  grid-column: 1 / span 1;
  grid-row: 10 / span 1;
}

.l-page__footer {
  grid-column: 1 / span 1;
  grid-row: 11 / span 1;
}

.l-page__survey {
  display: none;
  grid-column: 1 / span 1;
  grid-row: 12 / span 1;
}

.l-section-brand {
  background-color: #285b8d;
  background-image: url("/assets/img/bg-2.jpg");
  background-size: cover;
  padding: 4.5rem 1.5rem;
}

@media (min-width: 56.25em) {
  .l-section-brand {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, minmax(min-content, max-content));
  }
}

.brand__title {
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  line-height: 1.25;
  font-size: 2rem;
  color: white;
  text-align: center;
}

@media (min-width: 56.25em) {
  .brand__title {
    grid-column: 1 / span 12;
    grid-row: 1 / span 1;
  }
}

.brand__sub-title {
  font-size: 1.5rem;
  margin-bottom: 3rem;
  color: white;
  text-align: center;
}

@media (min-width: 56.25em) {
  .brand__sub-title {
    grid-column: 5 / span 4;
    grid-row: 2 / span 1;
  }
}

.brand__images {
  margin-bottom: 4.5rem;
}

@media (min-width: 56.25em) {
  .brand__images {
    grid-column: 2 / span 10;
    grid-row: 3 / span 1;
    display: flex;
    justify-content: space-between;
  }
}

.brand__image {
  margin-bottom: 2.25rem;
}

@media (min-width: 56.25em) {
  .brand__image {
    width: calc(33% - 1em);
    margin-bottom: 0;
  }
}

.brand__footer {
  padding-top: 3rem;
  border-top: 1px solid #31bef8;
}

@media (min-width: 56.25em) {
  .brand__footer {
    grid-column: 4 / span 6;
    grid-row: 4 / span 1;
    display: flex;
  }
}

.brand__footer-text {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 56.25em) {
  .brand__footer-text {
    flex: 2;
    padding-right: 1.5rem;
    margin-bottom: 0;
  }
}

.l-section-contact {
  background-color: #101C33;
  padding: 4.5rem 1.5rem;
}

@media (min-width: 56.25em) {
  .l-section-contact {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 7.5rem 1.5rem;
  }
}

.contact__content {
  text-align: center;
}

@media (min-width: 56.25em) {
  .contact__content {
    grid-column: 4 / 10;
  }
}

.contact__title {
  margin-bottom: 3rem;
  color: white;
  font-size: 1.5rem;
}

.contact__sub-title {
  margin-bottom: 4.5rem;
  color: white;
  font-size: 1.5rem;
  line-height: 1.5;
}

.contact__cta {
  margin-bottom: 1.5rem;
  color: #31bef8;
  font-size: 2rem;
  text-transform: uppercase;
}

.contact__info {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  line-height: 1.25;
  color: white;
}

.contact__info-link {
  color: white;
}

.contact__info-link:hover, .contact__info-link:focus, .contact__info-link:active {
  text-decoration: underline;
}

.form-view.theme5 {
  max-width: 100% !important;
  box-shadow: none !important;
  padding: 0;
  overflow: visible;
}

.form-view.theme5 .agile-field {
  width: 100% !important;
}

.form-view.theme5 .agile-group {
  margin-left: 0;
}

.form-view.theme5 .agile-group:last-child {
  margin-bottom: 0;
}

.form-view.theme5 .agile-field button {
  margin-top: 0 !important;
  padding: 0.25rem 2.5rem !important;
  cursor: pointer;
  font-size: 1rem;
  background: transparent;
}

.form-view.theme5 .agile-field button:hover, .form-view.theme5 .agile-field button:focus {
  background-color: #31bef8 !important;
}

.form-view.theme5 #agile-error-msg span {
  font-size: 1.5rem !important;
  color: white !important;
}

.form-view.theme5 #agile-error-msg img {
  display: inline-block;
  margin-top: 1.5rem;
  width: 16px !important;
}

.l-section-copy {
  background-color: #ECF9FF;
  padding: 4.5rem 1.5rem;
}

@media (min-width: 56.25em) {
  .l-section-copy {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, minmax(min-content, max-content));
    padding: 6rem 1.5rem;
  }
}

.copy__title {
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  line-height: 1.25;
  font-size: 2rem;
  color: #285b8d;
  text-align: center;
}

@media (min-width: 56.25em) {
  .copy__title {
    grid-column: 1 / span 12;
    grid-row: 1 / span 1;
  }
}

.copy__sub-title {
  font-size: 1.5rem;
  margin-bottom: 2.25rem;
  color: black;
  text-align: center;
}

@media (min-width: 56.25em) {
  .copy__sub-title {
    grid-column: 4 / span 6;
    grid-row: 2 / span 1;
  }
}

.copy__sub-title-2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #285b8d;
  text-align: center;
}

@media (min-width: 56.25em) {
  .copy__sub-title-2 {
    grid-column: 4 / span 6;
    grid-row: 3 / span 1;
    margin-bottom: 4.5rem;
  }
}

.copy__cards {
  margin-bottom: 4.5rem;
}

@media (min-width: 56.25em) {
  .copy__cards {
    grid-column: 2 / span 10;
    grid-row: 4 / span 1;
    display: flex;
    justify-content: space-between;
  }
}

.copy__card {
  padding: 2.25rem;
  margin-bottom: 2.25rem;
  background-color: #1d4268;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  color: white;
}

@media (min-width: 56.25em) {
  .copy__card {
    width: calc(33% - 1em);
    margin-bottom: 0;
  }
}

.copy__card-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: normal;
}

.copy__card-title-svg {
  width: 38px;
  height: 38px;
  margin-right: 0.75rem;
  fill: #31bef8;
  vertical-align: middle;
  display: inline-block;
}

.copy__footer {
  padding-top: 3rem;
  border-top: 1px solid #31bef8;
}

@media (min-width: 56.25em) {
  .copy__footer {
    grid-column: 3 / span 8;
    grid-row: 5 / span 1;
    display: flex;
  }
}

.copy__footer-text {
  color: #285b8d;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 56.25em) {
  .copy__footer-text {
    flex: 2;
    padding-right: 1.5rem;
    margin-bottom: 0;
  }
}

.l-section-photo {
  background-color: #285b8d;
  background-image: url("/assets/img/bg-1.jpg");
  background-size: cover;
  padding: 4.5rem 1.5rem 7.5rem;
}

@media (min-width: 56.25em) {
  .l-section-photo {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(4, minmax(min-content, max-content));
    padding: 6rem 1.5rem 6.75rem;
  }
}

.photo__title {
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  line-height: 1.25;
  font-size: 2rem;
  color: white;
  text-align: center;
}

@media (min-width: 56.25em) {
  .photo__title {
    grid-column: 1 / span 12;
    grid-row: 1 / span 1;
  }
}

.photo__sub-title {
  font-size: 1.5rem;
  margin-bottom: 3.75rem;
  color: white;
  text-align: center;
}

@media (min-width: 56.25em) {
  .photo__sub-title {
    grid-column: 4 / span 6;
    grid-row: 2 / span 1;
  }
}

.photo__images {
  margin-bottom: 4.5rem;
}

@media (min-width: 56.25em) {
  .photo__images {
    grid-column: 2 / span 10;
    grid-row: 3 / span 1;
    display: flex;
    justify-content: space-between;
  }
}

.photo__image {
  margin-bottom: 2.25rem;
  background-color: #666666;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

@media (min-width: 56.25em) {
  .photo__image {
    width: calc(33% - 1em);
    margin-bottom: 0;
  }
}

.photo__footer {
  padding-top: 3rem;
  border-top: 1px solid #31bef8;
}

@media (min-width: 56.25em) {
  .photo__footer {
    grid-column: 4 / span 6;
    grid-row: 4 / span 1;
    display: flex;
  }
}

.photo__footer-text {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 56.25em) {
  .photo__footer-text {
    flex: 2;
    padding-right: 1.5rem;
    margin-bottom: 0;
  }
}

.l-section-quote {
  background-color: #285b8d;
  padding: 4.5rem 1.5rem;
}

@media (min-width: 56.25em) {
  .l-section-quote {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}

.l-section-quote .m-blockquote {
  text-align: center;
  margin: 0;
}

@media (min-width: 56.25em) {
  .l-section-quote .m-blockquote {
    grid-column: 4 / 10;
  }
}

.l-section-quote .m-blockquote__body {
  margin-bottom: 1.5rem;
}

.l-section-quote .m-blockquote__footer {
  font-style: normal;
}

.l-section-solutions {
  background-color: #ECF9FF;
  padding: 4.5rem 1.5rem;
}

@media (min-width: 56.25em) {
  .l-section-solutions {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(4, minmax(min-content, max-content));
    padding: 6rem 1.5rem;
  }
}

.solutions__title {
  margin-bottom: 3rem;
  text-transform: uppercase;
  line-height: 1.25;
  font-size: 2rem;
  color: #285b8d;
}

.solutions__title span {
  display: block;
  text-transform: none;
  color: black;
  font-size: 1.5rem;
  font-weight: normal;
}

@media (min-width: 56.25em) {
  .solutions__title {
    grid-column: 2 / span 5;
    grid-row: 1 / span 1;
  }
}

.solutions__image {
  margin-bottom: 3rem;
  margin-left: -1.5rem;
}

@media (min-width: 56.25em) {
  .solutions__image {
    grid-column: 1 / span 7;
    grid-row: 2 / span 1;
  }
}

@media (min-width: 56.25em) {
  .solutions__content {
    grid-column: 8 / span 4;
    grid-row: 2 / span 1;
  }
}

.solutions__teaser {
  margin-bottom: 3rem;
  font-size: 1.5rem;
  color: #285b8d;
}

.solutions__sub-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #285b8d;
  text-transform: uppercase;
}

.solutions__cards {
  margin-bottom: 3rem;
}

@media (min-width: 56.25em) {
  .solutions__cards {
    align-self: center;
    margin-bottom: 6rem;
  }
}

.solutions__card {
  padding: 2.25rem;
  margin-bottom: 2.25rem;
  background-color: #1d4268;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  color: white;
}

.solutions__card-title {
  font-size: 1.5rem;
  margin-bottom: 1.875rem;
  font-weight: normal;
}

.solutions__card-title-svg {
  width: 38px;
  height: 38px;
  margin-right: 0.75rem;
  fill: #31bef8;
  vertical-align: middle;
  display: inline-block;
}

@media (min-width: 56.25em) {
  .solutions__logo-container {
    grid-column: 2 / span 10;
    grid-row: 3 / span 1;
  }
}

.solutions__logo-title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 3rem;
  color: #285b8d;
  line-height: 1.25;
  font-size: 2rem;
}

@media (min-width: 56.25em) {
  .solutions__logos {
    display: flex;
    justify-content: space-between;
  }
}

.solutions__logo {
  text-align: center;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  background-color: #c8d9e6;
  line-height: 100px;
}

@media (min-width: 56.25em) {
  .solutions__logo {
    width: calc(25% - 1em);
    margin-bottom: 0;
  }
}

.solutions__logo img {
  display: inline-block;
  vertical-align: middle;
  max-width: 180px;
}

.solutions__logo img.solutions__logo-square {
  max-width: 110px;
}

.l-section-web__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(5.25rem, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
  overflow: hidden;
}

@media (min-width: 56.25em) {
  .l-section-web__wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: minmax(5.25rem, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
  }
}

.l-section-web {
  grid-column: 1 / span 1;
  grid-row: 2 / span 3;
  background-color: #ECF9FF;
}

@media (min-width: 56.25em) {
  .l-section-web {
    grid-column: 1 / span 12;
    grid-row: 2 / span 2;
  }
}

.web__content {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
  padding: 3rem 1.5rem;
}

@media (min-width: 56.25em) {
  .web__content {
    grid-column: 2 / span 5;
    grid-row: 2 / span 1;
    padding: 6rem 0 6rem;
  }
}

.web__image {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
  padding: 0 4.5rem 3rem;
}

@media (min-width: 56.25em) {
  .web__image {
    grid-column: 7 / span 6;
    grid-row: 1 / span 2;
    margin-right: -1.5rem;
    padding: 0;
  }
}

.web__title {
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  line-height: 1.25;
  color: #285b8d;
  font-size: 2rem;
}

.web__sub-title {
  margin-bottom: 2.25rem;
  max-width: 37.5rem;
  color: black;
  font-size: 1.25rem;
}

.web__list-item {
  margin-bottom: 1.125rem;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjlweCIgaGVpZ2h0PSIyN3B4IiB2aWV3Qm94PSIwIDAgMjkgMjciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5TbGljZSAxPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwIiBmaWxsPSIjMzFCRUY4Ij4KICAgICAgICAgICAgPHBhdGggZD0iTTEyLjkxNzE0MjksMjcgQzUuODI4NTcxNDMsMjcgMC4wNiwyMS4yNjMyMTA1IDAuMDYsMTQuMjEwNTI2MyBDMC4wNiw3LjE1Nzg0MjExIDUuODI4NTcxNDMsMS40MjEwNTI2MyAxMi45MTcxNDI5LDEuNDIxMDUyNjMgTDEyLjkxNzE0MjksNC4yNjMxNTc4OSBDNy40MDI4NTcxNCw0LjI2MzE1Nzg5IDIuOTE3MTQyODYsOC43MjUyNjMxNiAyLjkxNzE0Mjg2LDE0LjIxMDUyNjMgQzIuOTE3MTQyODYsMTkuNjk1Nzg5NSA3LjQwMjg1NzE0LDI0LjE1Nzg5NDcgMTIuOTE3MTQyOSwyNC4xNTc4OTQ3IEMxOC40MzE0Mjg2LDI0LjE1Nzg5NDcgMjIuOTE3MTQyOSwxOS42OTU3ODk1IDIyLjkxNzE0MjksMTQuMjEwNTI2MyBMMjUuNzc0Mjg1NywxNC4yMTA1MjYzIEMyNS43NzQyODU3LDIxLjI2MzIxMDUgMjAuMDA1NzE0MywyNyAxMi45MTcxNDI5LDI3IFoiIGlkPSJQYXRoIj48L3BhdGg+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBwb2ludHM9IjE0LjQ3NzE0MjkgMTkuNjk1Nzg5NSA3LjM2ODU3MTQzIDEyLjYyNDYzMTYgOS44OTI4NTcxNCAxMC4xMTIyMTA1IDE0LjIxMjg1NzEgMTQuNDA5NDczNyAyNS44MiAwLjI5NTU3ODk0NyAyOC41ODQyODU3IDIuNTQ2NTI2MzIiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 20px;
  padding-left: 2.25rem;
  color: black;
  font-size: 1.2rem;
}

.web__footer {
  padding-top: 3rem;
  grid-column: 1 / span 1;
  grid-row: 4 / span 1;
  margin: 0 1.5rem;
  border-top: 1px solid #31bef8;
  margin-bottom: 4.5rem;
}

@media (min-width: 56.25em) {
  .web__footer {
    grid-column: 4 / span 6;
    grid-row: 3 / span 1;
    display: flex;
  }
}

.web__footer-text {
  color: #285b8d;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 56.25em) {
  .web__footer-text {
    flex: 2;
    padding-right: 1.5rem;
    margin-bottom: 0;
  }
}

.l-survey-cta {
  padding: 3rem 1.5rem;
  background-color: #F0FFF0;
}

@media (min-width: 56.25em) {
  .l-survey-cta {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 3rem 1.5rem;
  }
}

.survey-cta__text {
  font-size: 1.5em;
  line-height: 1.25;
  margin-bottom: 1.5rem;
}

@media (min-width: 56.25em) {
  .survey-cta__text {
    grid-column: 2 / 8;
    padding-right: 1em;
  }
}

@media (min-width: 56.25em) {
  .survey-cta__button-wrap {
    grid-column: 9 / 12;
    align-self: center;
    padding-left: 1em;
  }
}

.survey-cta__button {
  display: inline-block;
  padding: 1.125rem 2.25rem;
  color: #285b8d;
  font-size: 1.25rem;
  border: 4px solid #31bef8;
}

.survey-cta__button:hover {
  border: 4px solid #285b8d;
}


.u-clearfix:before,
.u-clearfix:after {
  display: table;
  content: '';
}


.u-clearfix:after {
  clear: both;
}

.u-remove-child--margin-top-bottom > :first-child,
.u-remove-child--margin-top > :first-child {
  margin-top: 0;
}

.u-remove-child--margin-top-bottom > :last-child,
.u-remove-child--margin-bottom > :last-child {
  margin-bottom: 0;
}


.u-text-hide {
  overflow: hidden;
  text-indent: 120%;
  white-space: nowrap;
}


.u-visually--hidden {
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}


.u-visually--hidden.focusable {
  background-color: #cccccc;
  padding: 0.75rem;
  color: #1d4268;
}


.u-visually--hidden.focusable:active,
.u-visually--hidden.focusable:focus {
  position: static;
  margin: 0;
  width: auto;
  height: auto;
  overflow: visible;
  text-decoration: underline;
  clip: auto;
}


.u-visually--shown {
  position: static;
  margin: 0;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  clip: rect(auto auto auto auto);
}

.u-word-break--pipe {
  display: block;
}

@media (min-width: 56.25em) {
  .u-word-break--pipe {
    display: inline;
  }
  .u-word-break--pipe:not(:last-of-type)::after {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
    content: '|';
  }
}
