.linkedin-profile {
  flex: 1;
  margin-left: 0;
  display: flex;

  &:hover {
    .linkedin-profile__name,
    .linkedin-profile__position {
      color: $blue-light;
    }
  }

  @include breakpoint($bp-large) {
    margin-bottom: 0;
  }
}

.linkedin-profile__content {
  min-width: ru(6);
}

.linkedin-profile__image {
  min-width: 75px;
  margin-right: ru(1);
}

.linkedin-profile__img {
  display: inline-block;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
}

.linkedin-profile__svg {
  width: 24px;
  height: 24px;
  fill: $black;
  vertical-align: middle;
  display: inline-block;
}

.linkedin-profile__name {
  text-transform: uppercase;
  color: $blue;
}

.linkedin-profile__position {
  margin-bottom: ru(0.25);
  color: $black;
}

// white text and icon variation
.linkedin-profile--light {
  .linkedin-profile__name,
  .linkedin-profile__position {
    color: $white;
  }

  .linkedin-profile__svg {
    fill: $white;
  }
}
