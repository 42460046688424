.l-section-solutions {
  background-color: $blue-xlight;
  padding: ru(3) ru(1);

  @include breakpoint($bp-large) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(4, minmax(min-content, max-content));
    padding: ru(4) ru(1);
  }
}

.solutions__title {
  margin-bottom: ru(2);
  text-transform: uppercase;
  line-height: 1.25;
  font-size: 2rem;
  color: $blue;

  span {
    display: block;
    text-transform: none;
    color: $black;
    font-size: 1.5rem;
    font-weight: normal;
  }

  @include breakpoint($bp-large) {
    grid-column: 2 / span 5;
    grid-row: 1 / span 1;
  }
}

.solutions__image {
  margin-bottom: ru(2);
  margin-left: ru(-1);

  @include breakpoint($bp-large) {
    grid-column: 1 / span 7;
    grid-row: 2 / span 1;
  }
}

.solutions__content {
  @include breakpoint($bp-large) {
    grid-column: 8 / span 4;
    grid-row: 2 / span 1;
  }
}

.solutions__teaser {
  margin-bottom: ru(2);
  font-size: 1.5rem;
  color: $blue;
}

.solutions__sub-title {
  font-size: 1.5rem;
  margin-bottom: ru(1);
  color: $blue;
  text-transform: uppercase;
}

.solutions__cards {
  margin-bottom: ru(2);

  @include breakpoint($bp-large) {
    align-self: center;
    margin-bottom: ru(4);
  }
}

.solutions__card {
  padding: ru(1.5);
  margin-bottom: ru(1.5);
  background-color: $blue-dark;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  color: $white;
}

.solutions__card-title {
  font-size: 1.5rem;
  margin-bottom: ru(1.25);
  font-weight: normal;
}

.solutions__card-title-svg {
  width: 38px;
  height: 38px;
  margin-right: ru(0.5);
  fill: $blue-light;
  vertical-align: middle;
  display: inline-block;
}

.solutions__logo-container {
  @include breakpoint($bp-large) {
    grid-column: 2 / span 10;
    grid-row: 3 / span 1;
  }
}

.solutions__logo-title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: ru(2);
  color: $blue;
  line-height: 1.25;
  font-size: 2rem;
}

.solutions__logos {
  @include breakpoint($bp-large) {
    display: flex;
    justify-content: space-between;
  }
}

.solutions__logo {
  text-align: center;
  margin-bottom: ru(1);
  padding: ru(1);
  background-color: #c8d9e6;
  line-height: 100px;

  @include breakpoint($bp-large) {
    width: calc(25% - 1em);
    margin-bottom: 0;
  }
}

.solutions__logo img {
  display: inline-block;
  vertical-align: middle;
  max-width: 180px;

  &.solutions__logo-square {
    max-width: 110px;
  }
}


