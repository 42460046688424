.l-header__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows:
    minmax(min-content, max-content)
    minmax(min-content, max-content)
    minmax(ru(3.5), max-content);
  overflow: hidden;

  @include breakpoint($bp-large) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows:
      minmax(min-content, max-content)
      minmax(ru(3.5), max-content);
  }
}

.l-header {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
  background-color: $blue;
  background-image: url('/assets/img/bg-1.jpg');
  background-size: cover;

  @include breakpoint($bp-large) {
    grid-column: 1 / span 12;
    grid-row: 1 / span 1;
  }
}

.header__content {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  margin-bottom: ru(1);
  padding-top: ru(2);
  padding-right: ru(1);
  padding-left: ru(1);

  @include breakpoint($bp-large) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 2 / span 5;
    grid-row: 1 / span 2;
    padding-top: ru(2);
    padding-right: 0;
    padding-bottom: ru(6);
  }
}

.header__image {
  grid-column: 1 / span 1;
  grid-row: 2 / span 2;
  padding-top: ru(1.5);
  padding-right: ru(3);
  padding-bottom: ru(1);
  padding-left: ru(0.5);

  @include breakpoint($bp-large) {
    display: flex;
    align-items: flex-end;
    grid-column: 7 / span 6;
    grid-row: 1 / span 2;
    margin-right: ru(-1);
    padding-top: ru(1.5);
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    opacity: 0;
    transform: translate3d(100px, 0, 0);
    animation: reveal-up 1s 0s cubic-bezier(0.215, 0.61, 0.355, 1) 1 forwards;
  }
}

.header__logo {
  margin-bottom: ru(2);
  opacity: 0;
  transform: translateY(100px);
  animation: reveal-up 1s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 1 forwards;

  @include breakpoint($bp-large) {
    margin-bottom: ru(3);
  }
}

.l-header__wrapper .m-logo__link {
  color: $white;

  span:last-child {
    color: $blue-light;
  }
}

.header__title {
  margin-bottom: ru(1.5);
  line-height: 1;
  color: $white;
  font-size: 4rem;
  opacity: 0;
  transform: translateY(100px);
  animation: reveal-up 1s 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 1 forwards;
}

.header__sub-title {
  opacity: 0;
  transform: translateY(100px);
  animation: reveal-up 1s 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 1 forwards;
  color: $white;
}

.header__sub-title-logo {
  display: inline-block;
  margin-bottom: ru(0.5);
  margin-right: ru(0.5);
  &:hover,
  &:active {
    outline: 1px solid $blue-light;
  }
}

.header__sub-title-logo-svg {
  vertical-align: middle;
  width: 163px;
  height: 33px;
}

.header__sub-title-logo__hidden-text {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.header__sub-title-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.3rem;
}

// Header Title animations

@keyframes reveal-up {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
