.l-page {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows:
    minmax(min-content, max-content)
    minmax(ru(3.5), max-content)
    minmax(min-content, max-content)
    minmax(min-content, max-content)
    minmax(min-content, max-content)
    minmax(min-content, max-content)
    minmax(ru(3.5), max-content)
    minmax(min-content, max-content)
    minmax(min-content, max-content)
    minmax(min-content, max-content)
    minmax(min-content, max-content)
    minmax(min-content, max-content);
}

.l-page__header {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
  z-index: 1;
}

.l-page__solutions {
  grid-column: 1 / span 1;
  grid-row: 2 / span 2;

  @include dbl;
}

.l-page__brand {
  grid-column: 1 / span 1;
  grid-row: 4 / span 1;
}

.l-page__copy {
  grid-column: 1 / span 1;
  grid-row: 5 / span 1;
}

.l-page__photo {
  grid-column: 1 / span 1;
  grid-row: 6 / span 2;
}

.l-page__web {
  grid-column: 1 / span 1;
  grid-row: 7 / span 2;
}

.l-page__quote {
  grid-column: 1 / span 1;
  grid-row: 9 / span 1;
}

.l-page__contact {
  grid-column: 1 / span 1;
  grid-row: 10 / span 1;
}

.l-page__footer {
  grid-column: 1 / span 1;
  grid-row: 11 / span 1;
}

.l-page__survey {
  // Temporarily hiding survey
  display: none;
  grid-column: 1 / span 1;
  grid-row: 12 / span 1;
}
