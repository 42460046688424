.l-survey-cta {
  padding: ru(2) ru(1);
  background-color: $green;

  @include breakpoint($bp-large) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: ru(2) ru(1);
  }
}

.survey-cta__text {
  font-size: 1.5em;
  line-height: 1.25;
  margin-bottom: ru(1);

  @include breakpoint($bp-large) {
    grid-column: 2 / 8;
    padding-right: 1em;
  }
}

.survey-cta__button-wrap {
  @include breakpoint($bp-large) {
    grid-column: 9 / 12;
    align-self: center;
    padding-left: 1em;
  }
}

.survey-cta__button {
  display: inline-block;
  padding: ru(0.75) ru(1.5);
  color: $blue;
  font-size: 1.25rem;
  border: 4px solid $blue-light;

  &:hover {
    border: 4px solid $blue;
  }
}
