.m-logo__link {
  color: $blue-light;
  text-transform: uppercase;
  font-size: 2rem;
  font-family: $title-font-family;
  font-weight: 700;

  @include breakpoint($bp-large) {
    font-size: 3rem;
  }
}
