.l-section-web__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows:
    minmax(ru(3.5), max-content) minmax(min-content, max-content)
    minmax(min-content, max-content) minmax(min-content, max-content);
  overflow: hidden;

  @include breakpoint($bp-large) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows:
      minmax(ru(3.5), max-content) minmax(min-content, max-content)
      minmax(min-content, max-content);
  }
}

.l-section-web {
  grid-column: 1 / span 1;
  grid-row: 2 / span 3;

  background-color: $blue-xlight;

  @include breakpoint($bp-large) {
    grid-column: 1 / span 12;
    grid-row: 2 / span 2;
  }
}

.web__content {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
  padding: ru(2) ru(1);

  @include breakpoint($bp-large) {
    grid-column: 2 / span 5;
    grid-row: 2 / span 1;
    padding: ru(4) 0 ru(4);
  }
}

.web__image {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
  padding: 0 ru(3) ru(2);

  @include breakpoint($bp-large) {
    grid-column: 7 / span 6;
    grid-row: 1 / span 2;
    margin-right: ru(-1);
    padding: 0;
  }
}

.web__title {
  margin-bottom: ru(1);
  text-transform: uppercase;
  line-height: 1.25;
  color: $blue;
  font-size: 2rem;
}

.web__sub-title {
  margin-bottom: ru(1.5);
  max-width: ru(25);
  color: $black;
  font-size: 1.25rem;
}

.web__list-item {
  margin-bottom: ru(0.75);
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjlweCIgaGVpZ2h0PSIyN3B4IiB2aWV3Qm94PSIwIDAgMjkgMjciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5TbGljZSAxPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwIiBmaWxsPSIjMzFCRUY4Ij4KICAgICAgICAgICAgPHBhdGggZD0iTTEyLjkxNzE0MjksMjcgQzUuODI4NTcxNDMsMjcgMC4wNiwyMS4yNjMyMTA1IDAuMDYsMTQuMjEwNTI2MyBDMC4wNiw3LjE1Nzg0MjExIDUuODI4NTcxNDMsMS40MjEwNTI2MyAxMi45MTcxNDI5LDEuNDIxMDUyNjMgTDEyLjkxNzE0MjksNC4yNjMxNTc4OSBDNy40MDI4NTcxNCw0LjI2MzE1Nzg5IDIuOTE3MTQyODYsOC43MjUyNjMxNiAyLjkxNzE0Mjg2LDE0LjIxMDUyNjMgQzIuOTE3MTQyODYsMTkuNjk1Nzg5NSA3LjQwMjg1NzE0LDI0LjE1Nzg5NDcgMTIuOTE3MTQyOSwyNC4xNTc4OTQ3IEMxOC40MzE0Mjg2LDI0LjE1Nzg5NDcgMjIuOTE3MTQyOSwxOS42OTU3ODk1IDIyLjkxNzE0MjksMTQuMjEwNTI2MyBMMjUuNzc0Mjg1NywxNC4yMTA1MjYzIEMyNS43NzQyODU3LDIxLjI2MzIxMDUgMjAuMDA1NzE0MywyNyAxMi45MTcxNDI5LDI3IFoiIGlkPSJQYXRoIj48L3BhdGg+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBwb2ludHM9IjE0LjQ3NzE0MjkgMTkuNjk1Nzg5NSA3LjM2ODU3MTQzIDEyLjYyNDYzMTYgOS44OTI4NTcxNCAxMC4xMTIyMTA1IDE0LjIxMjg1NzEgMTQuNDA5NDczNyAyNS44MiAwLjI5NTU3ODk0NyAyOC41ODQyODU3IDIuNTQ2NTI2MzIiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 20px;
  padding-left: ru(1.5);
  color: $black;
  font-size: 1.2rem;
}

.web__footer {
  padding-top: ru(2);
  grid-column: 1 / span 1;
  grid-row: 4 / span 1;
  margin: 0 ru(1);
  border-top: 1px solid $blue-light;
  margin-bottom: ru(3);

  @include breakpoint($bp-large) {
    grid-column: 4 / span 6;
    grid-row: 3 / span 1;
    display: flex;
  }
}

.web__footer-text {
  color: $blue;
  font-size: 1.2rem;
  margin-bottom: ru(1);

  @include breakpoint($bp-large) {
    flex: 2;
    padding-right: ru(1);
    margin-bottom: 0;
  }
}
