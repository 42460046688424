.l-section-contact {
  background-color: $blue-xdark;
  padding: ru(3) ru(1);

  @include breakpoint($bp-large) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: ru(5) ru(1);
  }
}

.contact__content {
  text-align: center;

  @include breakpoint($bp-large) {
    grid-column: 4 / 10;
  }
}

.contact__title {
  margin-bottom: ru(2);
  color: $white;
  font-size: 1.5rem;
}

.contact__sub-title {
  margin-bottom: ru(3);
  color: $white;
  font-size: 1.5rem;
  line-height: 1.5;
}

.contact__cta {
  margin-bottom: ru(1);
  color: $blue-light;
  font-size: 2rem;
  text-transform: uppercase;
}

.contact__info {
  margin-bottom: ru(1);
  font-size: 2rem;
  line-height: 1.25;
  color: $white;
}

.contact__info-link {
  color: $white;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

// Agilecrm form overrides

.form-view.theme5 {
  max-width: 100% !important;
  box-shadow: none !important;
  padding: 0;
  overflow: visible;
}

.form-view.theme5 .agile-field {
  width: 100% !important;
}

.form-view.theme5 .agile-group {
  margin-left: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.form-view.theme5 .agile-field button {
  margin-top: 0 !important;
  padding: 0.25rem 2.5rem !important;
  cursor: pointer;
  font-size: 1rem;
  background: transparent;

  &:hover,
  &:focus {
    background-color: $blue-light !important;
  }
}

.form-view.theme5 #agile-error-msg span {
  font-size: 1.5rem !important;
  color: $white !important;
}

.form-view.theme5 #agile-error-msg img {
  display: inline-block;
  margin-top: ru(1);
  width: 16px !important;
}
