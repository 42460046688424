.l-section-copy {
  background-color: $blue-xlight;
  padding: ru(3) ru(1);

  @include breakpoint($bp-large) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, minmax(min-content, max-content));
    padding: ru(4) ru(1);
  }
}

.copy__title {
  margin-bottom: ru(1);
  text-transform: uppercase;
  line-height: 1.25;
  font-size: 2rem;
  color: $blue;
  text-align: center;

  @include breakpoint($bp-large) {
    grid-column: 1 / span 12;
    grid-row: 1 / span 1;
  }
}

.copy__sub-title {
  font-size: 1.5rem;
  margin-bottom: ru(1.5);
  color: $black;
  text-align: center;

  @include breakpoint($bp-large) {
    grid-column: 4 / span 6;
    grid-row: 2 / span 1;
  }
}

.copy__sub-title-2 {
  font-size: 1.5rem;
  margin-bottom: ru(1);
  color: $blue;
  text-align: center;

  @include breakpoint($bp-large) {
    grid-column: 4 / span 6;
    grid-row: 3 / span 1;
    margin-bottom: ru(3);
  }
}

.copy__cards {
  margin-bottom: ru(3);

  @include breakpoint($bp-large) {
    grid-column: 2 / span 10;
    grid-row: 4 / span 1;
    display: flex;
    justify-content: space-between;
  }
}

.copy__card {
  padding: ru(1.5);
  margin-bottom: ru(1.5);
  background-color: $blue-dark;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  color: $white;

  @include breakpoint($bp-large) {
    width: calc(33% - 1em);
    margin-bottom: 0;
  }
}

.copy__card-title {
  font-size: 1.5rem;
  margin-bottom: ru(1);
  font-weight: normal;
}

.copy__card-title-svg {
  width: 38px;
  height: 38px;
  margin-right: ru(0.5);
  fill: $blue-light;
  vertical-align: middle;
  display: inline-block;
}

.copy__footer {
  padding-top: ru(2);
  border-top: 1px solid $blue-light;

  @include breakpoint($bp-large) {
    grid-column: 3 / span 8;
    grid-row: 5 / span 1;
    display: flex;
  }
}

.copy__footer-text {
  color: $blue;
  font-size: 1.2rem;
  margin-bottom: ru(1);

  @include breakpoint($bp-large) {
    flex: 2;
    padding-right: ru(1);
    margin-bottom: 0;
  }
}
