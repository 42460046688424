.l-section-photo {
  background-color: $blue;
  background-image: url('/assets/img/bg-1.jpg');
  background-size: cover;
  padding: ru(3) ru(1) ru(5);

  @include breakpoint($bp-large) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(4, minmax(min-content, max-content));
    padding: ru(4) ru(1) ru(4.5);
  }
}

.photo__title {
  margin-bottom: ru(1);
  text-transform: uppercase;
  line-height: 1.25;
  font-size: 2rem;
  color: $white;
  text-align: center;

  @include breakpoint($bp-large) {
    grid-column: 1 / span 12;
    grid-row: 1 / span 1;
  }
}

.photo__sub-title {
  font-size: 1.5rem;
  margin-bottom: ru(2.5);
  color: $white;
  text-align: center;

  @include breakpoint($bp-large) {
    grid-column: 4 / span 6;
    grid-row: 2 / span 1;
  }
}

.photo__images {
  margin-bottom: ru(3);

  @include breakpoint($bp-large) {
    grid-column: 2 / span 10;
    grid-row: 3 / span 1;
    display: flex;
    justify-content: space-between;
  }
}

.photo__image {
  margin-bottom: ru(1.5);
  background-color: $grey;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

  @include breakpoint($bp-large) {
    width: calc(33% - 1em);
    margin-bottom: 0;
  }
}

.photo__footer {
  padding-top: ru(2);
  border-top: 1px solid $blue-light;

  @include breakpoint($bp-large) {
    grid-column: 4 / span 6;
    grid-row: 4 / span 1;
    display: flex;
  }
}

.photo__footer-text {
  color: $white;
  font-size: 1.2rem;
  margin-bottom: ru(1);

  @include breakpoint($bp-large) {
    flex: 2;
    padding-right: ru(1);
    margin-bottom: 0;
  }
}
