%a-type__core {
  @include font-size($font-sizes--base);
  margin: 0 0 1rem 0;
}

p {
  @extend %a-type__core;
}

%a-type--small,
.a-type--small {
  @include font-size($font-sizes--small);
}

%a-type--large,
.a-type--large {
  @include font-size($font-sizes--large);
}

%a-type--xlarge,
.a-type--xlarge {
  @include font-size($font-sizes--xlarge);
}
