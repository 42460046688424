.l-footer {
  padding: ru(2) ru(1);
  text-align: center;
}

.l-footer .m-logo__link {
  font-size: 2rem;
  color: $blue;

  span:last-child {
    color: $blue-light;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}
